import React from "react";
import style from "./page.module.css"

function Page({children, styles}) {
  return (
    <div style={{padding: 15}} className={`${style.Page} ${styles}`}>
      {children}
    </div>
  )
}
export default Page;

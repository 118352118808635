import React   from "react";
import clsx from "clsx";
import styles from "./index.module.css"
import Spinner from "../Icons/Spinner";

export default function Loading({
  message = "Chargement de données...",
  className = "",
  spinnerClassName = "",
  small = false,
  width = null,
  fullPage = false,
  fullNav = false
}) {
  // Add small props to update style and remove text when loader inside button (cf login)
  return (
    <div className={clsx(fullPage ? [ styles.full_page,styles.wrapper ]: small ? styles.small_wrapper : fullNav ? styles.full_nav : styles.wrapper, className)} style={{width: width ?? undefined}}>
      <Spinner className={clsx(spinnerClassName, small ? styles.small_spinner : styles.spinner)} />
      {!!message && !small && <div>{message}</div>}
    </div>
  );
}


import {getData} from "./api";
import {TABLES} from "../lib/constants";

export const getEmployeesKey = (user_id) => ["getEmployees", user_id];
export const getEmployees = async (user_id) => getData({
  table: TABLES.EMPLOYEES,
  filter: {
    eq: {key: "user_id", value: user_id},
  }
});

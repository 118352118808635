import React from 'react'
import './index.css'
const FormInput = React.forwardRef((props, ref) => {
  const {htmlFor, onChange, errorMessage, label, required, type, name} = props
  return (
    <>
      <div className={"frame_input_label"}>
        <label className="input_label" htmlFor={htmlFor}>{label}<span> *</span> : </label>
      </div>
      <div className="input_container">
        <input
          className={errorMessage ? 'form_input_error'  : 'form_input'}
          type={type}
          name={name}
          ref={ref}
          required={required}
          onChange={onChange}
        />
        <small className="input_error_message">{errorMessage}</small>
      </div>
    </>  
  )
})

export default FormInput

export const {
  REACT_APP_SUPABASE_URL,
  REACT_APP_SUPABASE_KEY,
  REACT_APP_POWENS_CLIENT_ID,
  REACT_APP_POWENS_CLIENT_SECRET
} = process.env;

export const TABLES = {
  CONNECTION: 'connection',
  COMPANIES: 'companies',
  DOCUMENTS: 'documents',
  EMPLOYEES: 'employees',
  CONNECTOR: 'connector',
  USERS: 'users',
  USER_BI: 'user_bi',
  TOAST: 'toast'
}

import {ToastUtility} from "@syncfusion/ej2-react-notifications";

export const toastTimeOut = 4000;
export const toastPosition = { X: "Right", Y: "Bottom" };


export const toastWarning = "Warning";
export const toastError = "Error";
export const toastSuccess = "Success";



export const ToastError = error => {
  ToastContent({
    title: "Nom de Zeus !",
    content: JSON.stringify(error),
    type: "Error"
  });
}

export const ToastContent = (content, addContent) => {
  let icon, cssClass;

  switch(content.type){
  case 'Warning' :
    icon = "e-warning"
    cssClass = "toast-warning"
    break;

  case 'Error' :
    icon = "e-warning"
    cssClass = "toast-error"
    break;

  case 'Success' :
    icon = "e-check"
    cssClass = "toast-success"
    break;
  }

  ToastUtility.show({
    title: content.title,
    content: addContent ? addContent + content.content : content.content,
    timeOut: toastTimeOut,
    position: { X: "Right", Y: "Bottom" },
    type: content.type,
    showCloseButton: true,
    showProgressBar: true,
    cssClass: cssClass,
    icon: `e-icons ${icon}`,
    animation: {
      show: {
        effect: "SlideRightIn"
      },
      hide: {
        effect: "SlideRightOut"
      }
    }
  });
}

import {getData} from "./api";
import {TABLES} from "../lib/constants";

export const getDocumentsKey = (user_id) => ["getDocuments", user_id];
export const getDocuments = async (user_id) => getData({
  table: TABLES.DOCUMENTS,
  filter: {
    eq: {key: "user_id", value: user_id},
  }
});
export const getDocumentKey = (id) => ["getDocument", id];
export const getDocument = async (id) => getData({
  table: TABLES.DOCUMENTS,
  filter: {
    eq: {key: "id", value: id},
  }
});

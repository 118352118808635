import React, { useRef, useState } from "react";
import { supabase } from "../../lib/api";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { isPasswordValid } from "utils/utils";
import SubmitFormButton from "components/SubmitFormButton";
import FormErrorMessage from "components/FormErrorMessage";
import FormInput from "components/FormInput";
import AuthTemplate from "templates/AuthTemplate";

function RecoveryPassword() {
  const { token } = useParams();
  const [helperText, setHelperText] = useState({ error: null, text: null });
  const navigate = useNavigate();
  const newPasswordRef = useRef();
  const resetPasswordMutation = useMutation((data) => {
    return supabase.auth.api.updateUser(data.token, {
      password: data.password,
    });
  });
  const [errorPassword, setErrorPassword] = useState("");

  const handleNewPassword = async () => {
    // Form validation
    const password = newPasswordRef.current.value;
    if (!isPasswordValid(password)) {
      setErrorPassword("Le mot de passe doit faire au moins 6 caractères");
    } else {
      resetPasswordMutation.mutate({ token, password }, {onSuccess: ({ error }) => {
        if (!error) {
          // To render our Exploitation list again
          navigate("/");
        } else {
          // eslint-disable-next-line no-console
          console.error(error);
          if (error.message.indexOf("Invalid token: token is expired by") > -1) {
            setHelperText({
              error: true,
              text: "La demande de réinitialisation de mot de passe est expirée.",
            });
          }
        }
      }});
    }
  };

  const resetPasswordError = () => {
    if (errorPassword) setErrorPassword("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleNewPassword();
  };

  return (
    // <div className="login_page">
    //   <div className="login_frame">
    //     <div className="login_form recovery_login_form">
    // <img src={"https://sizvipsmrapmsfjmnirr.supabase.co/storage/v1/object/public/pub-farmpilot-assets/images/static-react/logo/color_bgtransparent_v_print.png?t=2022-08-05T08%3A02%3A05.371Z"} alt={"Logo"} className={"login_logo"} />
    <AuthTemplate>
      <div className="login_form_frame">
        <div className={"frame_login_label"}>
          <label className="login_label" htmlFor="email">
            Votre nouveau mot de passe<span className="">*</span> :
          </label>
        </div>
        <form onSubmit={handleSubmit}>
          <FormInput
            htmlFor="password"
            label="Votre mot de passe"
            errorMessage={errorPassword}
            onChange={resetPasswordError}
            ref={newPasswordRef}
            name="password"
            type="password"
            required
          />
        </form>
        <FormErrorMessage error={helperText.error} text={helperText.text} />
        <SubmitFormButton
          cssClass={"e-info"}
          width={240}
          onClick={handleNewPassword}
          isLoading={resetPasswordMutation.isLoading}
          text="Changer mon mot de passe"
        />
      </div>
    </AuthTemplate>
    //     </div>
    //     <div className={"frame_login_image"}>
    //       <img src={"https://sizvipsmrapmsfjmnirr.supabase.co/storage/v1/object/public/pub-farmpilot-assets/images/static-react/login/login.webp?t=2022-07-18T10%3A03%3A51.089Z"} alt={"Login"} className={"login_image"} />
    //     </div>
    //   </div>
    // </div>
  );
}

export default RecoveryPassword;

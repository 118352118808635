import React from "react";
import "./index.css";

function ButtonIconFP({onClick, content, color, icon}) {
  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={`frameButton ${color === "red" ? "bg-red" : color === "green" ? "bg-green" : "bg-yellow"}`} onClick={onClick}>
      <div className={"mr-1 icon"}>{icon}</div>
      <div className="textButton">
        {content}
      </div>
    </div>
  )
}

export default ButtonIconFP;

import './App.css';
import React, {useEffect} from "react";
import {Routes, Link, useNavigate, Route, Navigate} from "react-router-dom";
import {useAuth} from "./contexts/Auth";
import {getUserByUuid, getUserByUuidKey} from "./api/users";
import {useQuery} from "react-query";
import Loading from "./components/Loading";
import {getPage, getPage2, isPage} from "./utils/utils";
import {SidebarComponent} from "@syncfusion/ej2-react-navigations";
import {useState} from "react";
import {getRoutes} from "./routes";
import routes from "./routes";
import clsx from "clsx";
import {supabase} from "./lib/api";
import {ToastError} from "./utils/toast";

function App() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [dockBar, setDockBar] = useState(null);
  const [isDockBarOpen, setIsDockBarOption] = useState(false);
  const {data: user, isLoading: userLoading} = useQuery(getUserByUuidKey(auth?.id), () => getUserByUuid(auth?.id), {
    enabled: !!auth?.id
  });

  //?connection_id=6&code=hrqP7OlHpaZ6FwbpbKmrVLjfgf7kzY84pXzhcUp_gXX9MuBcEnI6Q7aWXxdM874wnc5qK2x9Dc43HbsTVF5L3NXO0lk2lJd0ubxQKWgXEUa9RAIfY6L4w%2F111DHj_lHR

  useEffect(() => {
    /* Recovery url is of the form
    * <SITE_URL>#access_token=x&refresh_token=y&expires_in=z&token_type=bearer&type=recovery
    * Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
    */
    const result = getPage();
    const result2 = getPage2();
    if (result.type === "recovery") {
      navigate("/recovery/"+ result.access_token);
    } else if (result2.connection_id && user) {
      const connection = {connection_id: result2.connection_id, code: result2.code, user_id: user.id};
      alert(JSON.stringify(connection));
      saveConnection(connection);
      //alert("/connection/"+ result2.connection_id+"/code/"+result2.code)
      //navigate("/connection/"+ result2.connection_id+"/code/"+result2.code);
    } else if (!auth) {
      navigate("/login");
    }
  }, [user])

  const saveConnection = async (connection) => {
    const {error} = await supabase
      .from("connection")
      .insert([connection]);
    if(error)
      ToastError(error);
    else
      ToastError('La connexion a été enregistrée !');
  }

  const toggleClick = () => {
    dockBar.toggle();
    setIsDockBarOption(!isDockBarOpen)
  }

  const onCreateSidebar= () => {
    dockBar.hide();
  }

  if(userLoading) return <Loading fullPage />
  return (
    <div className="control-section" id="targetElement">
      <div id="wrapper">
        {!!user && !isPage("recovery") && (<SidebarComponent id="dockSidebar" ref={Sidebar => setDockBar(Sidebar)} enableDock={true} dockSize="72px" width={'220px'} created={onCreateSidebar} isOpen={false}>
          <div className="dock">
            <ul>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <li className="sidebar-item" id="toggle" data-id="1" onClick={() => toggleClick()}>
                <div>
                  {isDockBarOpen ? <i className={"icon_menu_lateral fa-solid fa-chevron-left fa-2xl pl-5"} /> : <i className={"fa-solid fa-chevron-right fa-2xl pl-5"} />}
                </div>
              </li>
              <Link name="dashboard" data-id="2" to={"/"}>
                <li className={clsx(isPage("") && "active", "sidebar-item")}>
                  <i className={"icon_menu_lateral fas fa-home fa-2xl"} />
                  <span className="e-text" title="home">Dashboard</span>
                </li>
              </Link>

              <Link name="profile" data-id="2" to={"/profile"}>
                <li className={`${isPage("profile") ? "active" : ""} sidebar-item`} style={{bottom: 120, position: "absolute"}}>
                  <i className={"icon_menu_lateral fas fa-user fa-2xl"} />
                  <span className="e-text" title="exploitations">Profil</span>
                </li>
              </Link>

            </ul>
          </div>
        </SidebarComponent>)}
        <div className={"main-content"} id="main-content container-fluid col-md-12">
          <div className={!isPage("error") ? "content":""}>
            <Routes>
              {getRoutes(routes())}
              <Route path="*" element={<Navigate to="/error" />} />
            </Routes>
            {isPage("") && <div className={"footer"}>
              <a href={"https://app.netlify.com/sites/farmpilot/deploys"}><img src={"https://api.netlify.com/api/v1/badges/acde046d-b08b-464a-b8ee-42eddcfa6c86/deploy-status"} alt="Netlify Status" /></a>
              <a href={"/politique-de-confidentialite.pdf"}>Politique de confidentialité</a>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

import {getData} from "./api";
import {TABLES} from "../lib/constants";

export const getConnectorsKey = "getConnectors";
export const getConnectors = async () => getData({
  table: TABLES.CONNECTOR,
});
export const getConnectorKey = (id) => ["getConnector", id];
export const getConnector = async (id) => getData({
  table: TABLES.CONNECTOR,
  filter: {
    eq: {key: "id", value: id},
  }
});

import {getData} from "./api";
import {TABLES} from "../lib/constants";

export const getCompanyKey = (id) => ["getCompany", id];
export const getCompany = async (id) => getData({
  table: TABLES.COMPANIES,
  filter: {
    eq: {key: "id", value: id},
  }
});

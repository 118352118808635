import React, { useRef, useState, useEffect } from "react";
import { supabase } from "../../lib/api";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { getUserByUuid } from "../../api/users";
import { ToastError } from "../../utils/toast";
import {useMutation, useQueryClient} from "react-query";
import {isEmailValid} from "utils/utils";
import { isPasswordValid } from "utils/utils";
import SubmitFormButton from "components/SubmitFormButton";
import FormInput from "components/FormInput";
import FormErrorMessage from "components/FormErrorMessage";

import { useAuth } from "contexts/Auth";
import AuthTemplate from "templates/AuthTemplate";
import { getToast } from "api/toasts";
import { ToastContent } from "../../utils/toast";
import {getPowensLoginInit} from "../../api/powens";

function Auth() {
  const queryClient = useQueryClient();
  const [forgetPassword, setForgetPassword] = useState(false);
  const navigate = useNavigate();
  const [helperText, setHelperText] = useState({ error: null, text: null });
  const emailRef = useRef();
  const passwordRef = useRef();
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [isUserLoading, setIsUserLoading] = useState(false);
  const auth = useAuth();

  // use React query mutation -> Provide loading state
  const loginMutation = useMutation((credentials) =>
    supabase.auth.signIn(credentials)
  );
  const resetPasswordRequestMutation = useMutation((email) =>
    supabase.auth.api.resetPasswordForEmail(email)
  );

  useEffect(() => {
    if (auth) navigate("/");
    else queryClient.invalidateQueries();
  }, [auth]);

  const handleLogin = async () => {
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    if (helperText.error) {
      setHelperText({ error: false, text: "" });
    }
    // Form validation
    let isValid = true;
    if (!isPasswordValid(password)) {
      setErrorPassword("Le mot de passe doit faire au moins 6 caractères");
      isValid = false;
    }
    if (!isEmailValid(email)) {
      setErrorEmail("L'email saisi est incorrect.");
      isValid = false;
    }
    if (isValid) {
      loginMutation.mutate(
        { email, password },
        {
          onSuccess: ({ user, error }) => {
            if (error) {
              setHelperText({ error: true, text: error.message });
            } else if (!user.email_confirmed_at) {
              setHelperText({
                error: false,
                text: "Un email vient de vous être envoyé pour vérification.",
              });
            } else {
              // In async function because it's a bad practise to use Async in useEffect
              handleUser(user);
            }
          },
        }
      );
    }
  };

  const handleUser = async (user) => {
    setIsUserLoading(true);
    let utilisateur = await getUserByUuid(user.id);
    if (!utilisateur) {
      const powens_user = await getPowensLoginInit();
      utilisateur = { user_uuid: user.id, user_powens_token: powens_user.auth_token, user_powens_id: powens_user.id_user };
      let { error } = await supabase
        .from("user")
        .insert([utilisateur]);
      if (error) {
        ToastError(error);
      }
    }

    setIsUserLoading(false);
  };

  const handleOAuthLogin = async (provider) => {
    // You need to enable the third party auth you want in Authentication > Settings
    // Read more on: https://supabase.com/docs/guides/auth#third-party-logins
    const { error } = await supabase.auth.signIn({ provider });
    // eslint-disable-next-line no-console
    if (error) console.log("Error: ", error.message);
  };

  //TODO Add validation here
  const forgotPassword = async () => {
    // Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
    const email = emailRef.current?.value;
    if (email === null || email === "") {
      setErrorEmail("Vous devez entrer votre email."); // Error directly under the field
    } else if (!isEmailValid(email)) {
      setErrorEmail("L'email saisi est incorrect.");
    } else {
      resetPasswordRequestMutation.mutate(email, {
        onSuccess: async ({ error }) => {
          if (error) {
            // eslint-disable-next-line no-console
            console.error("Error: ", error.message);
          } else {
            setForgetPassword(false);
            ToastContent(await getToast(28));
          }
        },
      });
    }
  };

  const translate = (text) => {
    switch (text) {
    case "Invalid login credentials":
      return "Identifiants incorrects";
    case "Signup requires a valid password":
      return "Vous devez renseigner un mot de passe valide";
    case "You must provide either an email, phone number, a third-party provider or OpenID Connect":
      return "Vous devez renseigner une adresse email valide";
    default:
      return helperText.text;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!forgetPassword) handleLogin();
    else forgotPassword();
  };

  // Remove form error messages when input changes
  const resetPasswordError = () => {
    if (errorPassword) setErrorPassword("");
  };

  const resetEmailError = () => {
    if (errorEmail) setErrorEmail("");
  };

  return (
    <AuthTemplate>
      <form className="login_form_frame" onSubmit={handleSubmit}>
        <img
          src={
            "https://sizvipsmrapmsfjmnirr.supabase.co/storage/v1/object/public/pub-farmpilot-assets/images/static-react/logo/color_bgtransparent_hori.svg"
          }
          alt={"Logo"}
          className={"login_logo"}
        />
        <div>
          <h3>
            De{" "}
            <span>
              <b>retour !</b>
            </span>
          </h3>
          <p className={"login_no_account"}>
            {
              "Re bienvenue sur votre application FarmPilot. Entrez vos identifiants pour vous connecter. Vous n’avez pas de compte ? "
            }
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
              className="login_forgetpassword"
              onClick={() => navigate("/inscription")}
            >
              Inscrivez-vous
            </span>
          </p>
        </div>
        <FormInput
          htmlFor="email"
          label="Votre e-mail"
          errorMessage={errorEmail}
          onChange={resetEmailError}
          ref={emailRef}
          name="email"
          type="email"
          required
        />
        {!forgetPassword && (
          <FormInput
            htmlFor="password"
            label="Votre mot de passe"
            errorMessage={errorPassword}
            onChange={resetPasswordError}
            ref={passwordRef}
            name="password"
            type="password"
            required
          />
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span
          className="login_forgetpassword col-md-12"
          onClick={() => setForgetPassword((prev) => !prev)}
        >
          {!forgetPassword
            ? "J'ai oublié mon mot de passe"
            : "Revenir à la connexion"}
        </span>
        <FormErrorMessage
          error={helperText.error}
          text={translate(helperText.text)}
        />

        {!forgetPassword ? (
          <SubmitFormButton
            width={160}
            onClick={handleLogin}
            isLoading={loginMutation.isLoading || isUserLoading}
            text="Se connecter"
          />
        ) : (
          <SubmitFormButton
            width={300}
            onClick={forgotPassword}
            isLoading={resetPasswordRequestMutation.isLoading}
            text="Réinitialiser mon mot de passe"
          />
        )}
        <hr className={"hr_login"}></hr>
        <div className={"login_connect_compte"}>
          <p>Ou connectez-vous avec :</p>
          <SubmitFormButton
            width={240}
            onClick={() => handleOAuthLogin("google")}
            isLoading={false}
            text="Se connecter avec Google"
          />
          <p className={"login_droits"}>
            © De IT INNOVE. Tous droits réservés.
          </p>
        </div>
      </form>
    </AuthTemplate>
  );
}

export default Auth;

import React from "react";
import "./index.css";
import {DialogComponent} from "@syncfusion/ej2-react-popups";

function Dialog({children, width, height, header, showDialog, dialogClose, Class, target="#targetElement"}) {
  return (
    <div className="control-pane targetElement">
      <div
        className="control-section col-lg-12 defaultDialog dialog-target"
      >
        <div className={"dialog"}>
          <DialogComponent
            width={width}
            height={height}
            header={header}
            target={target}
            visible={showDialog}
            showCloseIcon={true}
            close={dialogClose}
            cssClass={Class}
            position={{X: "center", Y: 'top'}}
          >
            {children}
          </DialogComponent>
        </div>
      </div>
    </div>
  )
}

export default Dialog;

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Loading from 'components/Loading'
import React from 'react'
import "./index.css";

const SubmitFormButton = ({onClick, isLoading, text, width, cssClass = null}) => {
  return (
    <div className={"form_button_container"}>
      <ButtonComponent
        onClick={onClick}
        type="submit"
        className={`form_button ${isLoading ? 'form_button_loading' : undefined}`}
        style={{width}}
        cssClass={cssClass}
      > 
        {isLoading ? <Loading width={width} small /> : <span>{text}</span>}
      </ButtonComponent>
    </div>
  )
}

export default SubmitFormButton

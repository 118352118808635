import axios from "axios";
import { REACT_APP_POWENS_CLIENT_ID, REACT_APP_POWENS_CLIENT_SECRET } from "../lib/constants";
import {powensUrl, supabase} from "../lib/api";

export const getPowensLoginInitKey = "getPowensLoginInit";
export const getPowensLoginInit = async () => {
  return (await axios({
    method: 'post',
    url: powensUrl+'auth/init',
    data: {
      "client_id": REACT_APP_POWENS_CLIENT_ID,
      "client_secret": REACT_APP_POWENS_CLIENT_SECRET,
    },
  })).data;
}
export const getPowensLoginRenewKey = ({user_id}) => ["getPowensLoginRenew", {user_id}];
export const getPowensLoginRenew = async ({user_id}) => {
  return (await axios({
    method: 'post',
    url: powensUrl+'auth/renew',
    data: {
      "client_id": REACT_APP_POWENS_CLIENT_ID,
      "client_secret": REACT_APP_POWENS_CLIENT_SECRET,
      "id_user": user_id,
      "revoke_previous": false
    },
  })).data;
}

export const getAllDocumentsKey = ({user_id, bearer}) => ["getAllDocuments", {user_id, bearer}];
export const getAllDocuments = async ({user_id, bearer}) => {
  return (await axios({
    method: 'get',
    url: powensUrl+'users/'+user_id+'/documents',
    headers: {
      'Authorization': 'Bearer ' + bearer
    }
  })).data.documents;
}

export const getAllSubscriptionsKey = ({user_id, bearer}) => ["getAllSubscriptions", {user_id, bearer}];
export const getAllSubscriptions = async ({user_id, bearer}) => {
  return (await axios({
    method: 'get',
    url: powensUrl+'users/'+user_id+'/subscriptions',
    headers: {
      'Authorization': 'Bearer ' + bearer
    }
  })).data.subscriptions;
}

export const getAllConnectorsKey = (bearer) => ["getAllConnectors", bearer];
export const getAllConnectors = async (bearer) => {
  return (await axios({
    method: 'get',
    url: powensUrl+'connectors',
    headers: {
      'Authorization': 'Bearer ' + bearer
    }
  })).data.connectors;
}

export const getDocument = async ({url, bearer}) => {
  return (await axios({
    method: 'get',
    url: url,
    headers: {
      'Authorization': 'Bearer ' + bearer,
      responseType: 'blob'
    },
  })).data;
}

/*export const parseDocument = async (raw) => {
  const callServer = await axios.get(raw)
  return callServer.data
}*/


export const parseDocument = async ({url, bearer}) => {
  const callServer = await axios.get(url, {
    headers: {'Authorization': 'Bearer ' + bearer,},
    responseType: 'blob'
  })
  return callServer.data
}


export const syncDocuments = async ({login, user, documents, documents_powens}) => {
  for (const document of documents_powens) {
    if (!documents.find(doc => doc.document_powens_id === document.id)) {
      const file = await parseDocument({url: document.url, bearer: login?.access_token});
      const document_name = document.url.split("/")[document.url.split("/")?.length - 1];  // document.issuer+"_"+document.id+document.name.split(".")[document.name.split(".")?.length - 1]; //document.name.split("/")[document.name.split("/")?.length - 1];
      const bucket = "priv-documents";
      await supabase.storage.from(bucket).upload(user.id + '/' + document.issuer + '/' + document_name, file);
      const url = bucket + "/" + user.id + '/' + document.issuer + '/' + document_name;

      const doc = {
        document_powens_id: document.id,
        name: document.name,
        issuer: document.issuer,
        date: document.date,
        total_amount: document.total_amount,
        symbol: document?.currency?.symbol,
        user_id: user.id,
        url: url
      };
      await supabase
        .from("document")
        .insert([doc]);
    }
  }
}

export const syncConnectors = async ({connectors, connectors_powens}) => {
  for (const connector of connectors_powens) {
    if (!connectors.find(con => con.id === connector.id)) {
      const c = {
        id: connector.id,
        name: connector.name,
        hidden: connector.hidden,
        charged: connector.charged,
        code: connector.code,
        beta: connector.beta,
        color: connector.color,
        slug: connector.slug,
        sync_periodicity: connector.sync_periodicity,
        months_to_fetch: connector.months_to_fetch,
        siret: connector.siret,
        uuid: connector.uuid,
        restricted: connector.restricted,
        stability: JSON.stringify(connector.stability),
        auth_mechanism: connector.auth_mechanism
      };
      const {data, error} = await supabase
        .from("connector")
        .insert([c]);

      if(error)
        alert(JSON.stringify(error));

      if(connector.capabilities?.length > 0) {
        for (const cap of connector.capabilities) {
          const cc = {
            connector_id: data[0].id,
            capability: cap
          }
          await supabase
            .from("connector_capability")
            .insert([cc]);
        }
      }
      if(connector.urls?.length > 0) {
        for (const url of connector.urls) {
          const cu = {
            connector_id: data[0].id,
            url: url
          }
          await supabase
            .from("connector_url")
            .insert([cu]);
        }
      }
      if(connector.available_auth_mechanisms?.length > 0) {
        for (const available_auth_mechanism of connector.available_auth_mechanisms) {
          const caam = {
            connector_id: data[0].id,
            available_auth_mechanism: available_auth_mechanism
          }
          await supabase
            .from("connector_available_auth_mechanism")
            .insert([caam]);
        }
      }
      if(connector.categories?.length > 0) {
        for (const category of connector.categories) {
          const cc = {
            connector_id: data[0].id,
            available_auth_mechanism: category
          }
          await supabase
            .from("connector_category")
            .insert([cc]);
        }
      }
      if(connector.account_types?.length > 0) {
        for (const account_type of connector.account_types) {
          const cat = {
            connector_id: data[0].id,
            account_type: account_type
          }
          await supabase
            .from("connector_account_type")
            .insert([cat]);
        }
      }
      if(connector.account_usages?.length > 0) {
        for (const account_usage of connector.account_usages) {
          const cau = {
            connector_id: data[0].id,
            account_usage: account_usage
          }
          await supabase
            .from("connector_account_usage")
            .insert([cau]);
        }
      }
      if(connector.documents_type?.length > 0) {
        for (const document_type of connector.documents_type) {
          const cdt = {
            connector_id: data[0].id,
            document_type: document_type
          }
          await supabase
            .from("connector_document_type")
            .insert([cdt]);
        }
      }
      if(connector.products?.length > 0) {
        for (const product of connector.products) {
          const cp = {
            connector_id: data[0].id,
            product: product
          }
          await supabase
            .from("connector_product")
            .insert([cp]);
        }
      }
    }
  }
}

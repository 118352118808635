import React from "react";
import ButtonFP from "../../components/ButtonFP";
import {useNavigate} from "react-router-dom";
import "./index.css"
import {supabase} from "../../lib/api";
function Error() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    // eslint-disable-next-line no-console
    supabase.auth.signOut().catch(console.error);
    navigate("/login");
  };

  return (
    <div style={{backgroundColor: "white", overflow: "hidden", height: "100vh"}}>
      <div className={"row"}>
        <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12 ombre"}>

          <div className={" error_center "}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h3 className={"mb-2"}>Page non trouvée</h3>
          </div>
        </div>
      </div>
      <div className={"row"} style={{padding: 15}}>
        <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>

          <div className={"mt-5 error_center"}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <h3 className={"mb-2"}>Nous sommes désolé, la page que vous cherchez n'existe pas.</h3>
          </div>
        </div>
        <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
          <div className={"mt-4 error_center"}>
            <ButtonFP type="submit" onClick={() => navigate("/")} cssClass={"e-flat"} content={"Page d'accueil"}></ButtonFP>
          </div>
        </div>
        <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
          <div className={"mt-5 error_center"}>
            <img src={"https://sizvipsmrapmsfjmnirr.supabase.co/storage/v1/object/public/pub-farmpilot-assets/images/static-react/404/404.png?t=2022-08-29T21%3A01%3A49.685Z"} alt={"Logo"} className={"error_image"} />
          </div>
        </div>

        <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
          <div className={"mb-3 mt-3 error_center error_link"}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            Voici quelques liens utiles : <a href={"/exploitations"} className={"link_error"}>Recherche</a> <a href={"/profile"} className={"link_error"}>Profil</a> <a href={"/charts"} className={"link_error"}>Charts</a> <div onClick={() => handleLogout()} className={"link_error"}>Déconnexion</div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default Error;

import React from "react";
import "./index.css";

function IconHeaderFP({onClick, icon, style = ""}) 
{
  
  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={`frameButtonHeader ${style}`} onClick={onClick}>
      <div className={"icon"}>{icon}</div>
    </div>
  )
}

export default IconHeaderFP;

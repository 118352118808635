import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./_variables.css"
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(
  "ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0ViWn5YdHRVQGJdUU0=\n"
);
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { AuthProvider } from "./contexts/Auth";
import Theme from "./components/Theme";

Bugsnag.start({
  apiKey: "143756d9eb6e1d6dc338b9ff45e37175",
  plugins: [new BugsnagPluginReact()]
});
const ErrorBoundary = Bugsnag.getPlugin("react")
  .createErrorBoundary(React)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'tracked',
    },
  },
});


ReactDOM.render(
  <ErrorBoundary>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <BrowserRouter>
            <Theme />
            <App />
          </BrowserRouter>
        </React.StrictMode>
      </QueryClientProvider>
    </AuthProvider>
  </ErrorBoundary>,
  document.getElementById("root") || document.createElement("div")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

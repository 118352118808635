export const isPasswordValid = (password) => {
  return password.length >= 6;
};

export const isPage = (page) => {
  const url = window.location.pathname;
  return url.split("/")[1] === page;
};

export const getPageArguments = () => {
  const url = window.location.search;
  const query = url.slice(1);
  let result = {};
  query.split("&").forEach((part) => {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const isEmailValid = (email) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
};

export const getPage = () => {
  const url = window.location.hash;
  const query = url.slice(1);
  let result = {};
  query.split("&").forEach((part) => {
    const item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};
export const getPage2 = () => {
  const url = window.location.href;
  const query = url.split('?')[1];
  let result = {};
  if(query && query.length > 0) {
    query.split("&").forEach((part) => {
      const item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });
  }
  return result;
};
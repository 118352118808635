import React, { useRef, useState, useEffect } from "react";
import { supabase } from "../../lib/api";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { getUserByUuid } from "../../api/users";
import { ToastError } from "../../utils/toast";
import { useAuth } from "../../contexts/Auth";
import { getToast } from "../../api/toasts";
import { useMutation } from "react-query";
import { isPasswordValid, isEmailValid } from "utils/utils";
import FormInput from "components/FormInput";
import SubmitFormButton from "components/SubmitFormButton";
import FormErrorMessage from "components/FormErrorMessage";
import styles from "./index.module.css";
import AuthTemplate from "templates/AuthTemplate";
import { ToastContent } from "../../utils/toast";

function Inscription() {
  const navigate = useNavigate();
  const auth = useAuth();

  const [helperText, setHelperText] = useState({ error: null, text: null });
  const [rgpd, setRgpd] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const signupMutation = useMutation((credentials) =>
    supabase.auth.signUp(credentials)
  );
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");

  const handleChangeRgpd = (value) => {
    setRgpd(value.checked);
  };

  useEffect(() => {
    if (auth) navigate("/");
  }, [auth]);

  const handleRegister = async () => {
    if (rgpd) {
      const email = emailRef.current?.value;
      const password = passwordRef.current?.value;
      // Form validation
      let isValid = true;
      if (!isPasswordValid(password)) {
        setErrorPassword("Le mot de passe doit faire au moins 6 caractères");
        isValid = false;
      }
      if (!isEmailValid(email)) {
        setErrorEmail("L'email saisi est incorrect.");
        isValid = false;
      }
      if (isValid) {
        signupMutation.mutate({ email, password }, {onSuccess: ({ user, error }) => {
          if (error) {
            setHelperText({ error: true, text: error.message });
          } else if (!user.email_confirmed_at) {
            setHelperText({
              error: false,
              text: "Un email vient de vous être envoyé pour vérification.",
            });
          } else {
            handleUser(user);
          }
        }});
      }
    } else {
      ToastContent(await getToast(21));
    }
  };

  const handleUser = async (user) => {
    let utilisateur = await getUserByUuid(user.id);
    if (!utilisateur) {
      utilisateur = { user_uuid: user.id, email: user.email };
      let { error } = await supabase
        .from("users")
        .insert([utilisateur]);
      if (error) {
        ToastError(error);
      }
    }
    navigate("/");
  };

  const navigateToLogin = () => navigate("/login");

  const translate = (text) => {
    switch (text) {
    case "Invalid login credentials":
      return "Identifiants incorrects";
    case "Signup requires a valid password":
      return "Vous devez renseigner un mot de passe valide";
    case "You must provide either an email, phone number, a third-party provider or OpenID Connect":
      return "Vous devez renseigner une adresse email valide";
    default:
      return helperText.text;
    }
  };

  // Remove form error messages when input changes
  const resetPasswordError = () => {
    if (errorPassword) setErrorPassword("");
  };
  const resetEmailError = () => {
    if (errorEmail) setErrorEmail("");
  };

  return (
    <AuthTemplate>
      <div className="login_form_frame">
        <div>
          <h3>
            Se créer{" "}
            <span>
              <b>un compte</b>
            </span>
          </h3>
          <p className={"login_no_account"}>
            {
              "Entrez vos informations pour vous créer un compte sur FarmPilot. Vous avez déjà un compte ? "
            }
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span className="login_forgetpassword" onClick={navigateToLogin}>
              Se connecter
            </span>
          </p>
        </div>
        <FormInput
          htmlFor="email"
          label="Votre e-mail"
          errorMessage={errorEmail}
          onChange={resetEmailError}
          ref={emailRef}
          name="email"
          type="email"
          required
        />
        <FormInput
          htmlFor="password"
          label="Votre mot de passe"
          errorMessage={errorPassword}
          onChange={resetPasswordError}
          ref={passwordRef}
          name="password"
          type="password"
          required
        />

        <div className={"frame_login_label " + styles.div_politique_inscription}>
          <CheckBoxComponent
            name={"melange"}
            value={rgpd}
            change={handleChangeRgpd}
          />
          <label className="rgpd_label" htmlFor="rgpd">
            <a
              href={"/politique-de-confidentialite.pdf"}
              className={styles.text_politique_inscription}
            >
              Politique de confidentialité{" "}
            </a>
            <span>*</span>
            <i className="fa-solid fa-arrow-up-right-from-square"></i>
          </label>
        </div>
        <FormErrorMessage
          error={helperText.error}
          text={translate(helperText.text)}
        />
        <SubmitFormButton
          width={160}
          onClick={handleRegister}
          isLoading={signupMutation.isLoading}
          text="S'inscrire"
        />
        <hr className={"hr_login"}></hr>
        <p className={"login_droits"}>© De IT INNOVE. Tous droits réservés.</p>
      </div>
    </AuthTemplate>
  );
}

export default Inscription;

import React from 'react'
import './index.css'
const FormErrorMessage = ({error, text}) => {
  return (
    <div
      className={`login_error_message ${
        error
          ? "login_helper_error"
          : "login_helper_info"
      }`}
    >
      {text}
    </div>
  )
}

export default FormErrorMessage

import {supabase} from "../lib/api";
import {ToastError} from "../utils/toast";
import {getToast} from "./toasts";
import { ToastContent } from "../utils/toast";
export const getData = async ({table, filter = {}, count = 0, offset = 0, showError = true}) => {
  let result = [];
  let request = supabase
    .from(table)
    .select(filter.select??"*", { count: "exact" });

  if (typeof filter.order === "object" && Object.entries(filter.order).length > 0) {
    request = request.order(filter.order.key, filter.order.value)
  }
  if (typeof filter.match === "object" && Object.entries(filter.match).length > 0) {
    request = request.match(filter.match)
  }
  if (typeof filter.in === "object" && Object.entries(filter.in).length > 0) {
    request = request.in(filter.in.key, filter.in.value)
  }
  if (typeof filter.eq === "object" && Object.entries(filter.eq).length > 0) {
    request = request.eq(filter.eq.key, filter.eq.value)
  }

  if (typeof filter.like === "object" && Object.entries(filter.like).length > 0) {
    request = request.like(filter.like.key, filter.like.value)
  }

  if (offset && count) {
    request = request.range(offset, count);
  }

  // console.log(request);

  const { data, error, count: requestCount } = await request;
  if(count === 0)
    count = requestCount;

  if (error && showError) {
    //ToastError(error);
    ToastContent(await getToast(3));
    throw error;
  }

  result = result.concat(data);

  if (count > (offset + data.length)) {
    result = result.concat(await getData({
      table,
      filter,
      count,
      offset: offset + data.length
    }))
  }

  return result;
};

/*export const getDataWithRange = async ({table, filter = {}, count, offset}) => {
  let result = [];
  let request = supabase
    .from(table)
    .select(filter.select??"*", { count: "exact" }).range(offset, offset + count)

  const { data, error } = await request;

  if (error) {
    ToastError(error);
    throw error;
  }

  result = result.concat(data);
  return result;
};*/

export const getDataCount = async ({table, filter = {}}) => {
  let request = supabase
    .from(table)
    .select(filter.select??"*", { count: "exact", head: true })

  const  {count, error} = await request;
  if (error) {
    ToastError(error);
    throw error;
  }
  return count
};

// Requetes simplifiées
export const getTable = async (table) => await getData({ table });

export const getMatchCampagneOrderId = async (table) => {
  const campagne = parseInt(localStorage.getItem("campagne"));
  return await getData({
    table: table,
    filter: {
      match: {"campagne_id": campagne},
      order: {key: "id", value: {ascending: false}}
    }
  })
};

export const getMatchCampagneOrderNom = async (table) => {
  const campagne = parseInt(localStorage.getItem("campagne"));
  if(!campagne || isNaN(campagne))
    throw "Campagne non définie";

  return await getData({
    table: table,
    filter: {
      match: {"campagne_id": campagne},
      order: {key: "nom", value: {ascending: true}}
    }
  })
};

export const doDelete = async (table, id, matchType = null) =>{
  return await supabase
    .from(table)
    .delete()
    .match(matchType ? {[matchType]: id} :{id: id})
}

export const doUpdate = async (table, object, id, matchType) =>{
  const {data, error} = await supabase
    .from(table)
    .update(object)
    .eq(matchType ? matchType : 'id', id);
  if(error) {
    ToastError(error);
    throw error;
  }
  return data ;
}

export const doInsert = async ({table, object}) => {
  const {data, error} = await supabase.from(table).insert([object]);
  if(error) {
    ToastError(error);
    throw error ;
  }
  return data ;
}

export const doUpsertData = async ({table, object}) => {
  let e, d;
  if(object.id) {
    const { data, error } = await doUpdate(table, object, object.id);
    e = error ;
    d = data;
  } else {
    delete object.id;
    const { data, error } = await doInsert({table, object});
    e = error ;
    d = data;
  }

  if(e) {
    ToastError(e);
    throw e;
  }
  return d;
}

export const actionUpsert = async ({table, value, toastError = true}) => {
  if (value.action === "edit") {
    const { data, error } = await supabase
      .from(table)
      .update(value.data)
      .eq('id', value.data.id)
    if (error && toastError) ToastError(error);
    return { data, error } ;
  }

  if (value.action === "add") {
    delete value.data.id;
    const { data, error } = await supabase
      .from(table)
      .insert(value.data)
    if (error && toastError) ToastError(error);
    return { data, error } ;
  }
}

// UPSERT
export const upsertData = async ({table, object}) => {
  const { data, error } = await supabase
    .from(table)
    .upsert(object)
  if (error) {
    ToastError(error);
    throw error;
  }
  else return data;
}


// GESTION DES FICHIERS DES BUCKETS

export const getSignedUrl = async ({bucket, path}) => {
  const {data, error} = await supabase
    .storage
    .from(bucket)
    .createSignedUrl(path, 600);
  if (error) {
    ToastError(error);
    throw error;
  }
  return data.signedURL ;
}

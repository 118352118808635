import React from "react";
import styles from "./index.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";

function ButtonFP({onClick, content, color, style = "", renderAsLink = false, to = "", target = null}) {
  const finalClassName = clsx(
    style,
    styles.frameButton,
    color === "red" ? styles.bgRed : color === "green" ? styles.bgGreen : styles.bgYellow,
  )

  if (renderAsLink) {
    return <Link to={to} target={target} className={finalClassName}>{content}</Link>
  }

  return (
    <button type="button" onClick={onClick} className={finalClassName}>
      {content}
    </button>
  )
}

export default ButtonFP;

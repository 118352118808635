import React from "react";
import { BreadcrumbComponent, BreadcrumbItemDirective, BreadcrumbItemsDirective } from "@syncfusion/ej2-react-navigations";
import "./index.css";
import ButtonFP from "../ButtonFP";
import ButtonIconFP from "../ButtonIconFP";
import IconHeaderFP from "components/IconHeaderFP";

function Breadcrumbs({breadcrumbs = [], buttonsLeft=[], buttonsRight=[]}) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <div className="navbar-brand">
          {buttonsLeft.map((buttonLeft, index) => (
            <ButtonIconFP
              key={"buttonLeft"+index}
              type="submit"
              icon={<i className="fas fa-chevron-left" />}
              onClick={buttonLeft.onClick}
              content={buttonLeft.content ?? "Retour"} />
          ))}
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <BreadcrumbComponent enableNavigation={true} >
              <BreadcrumbItemsDirective>
                <BreadcrumbItemDirective iconCss="e-icons e-home" url="/" text= "Accueil"/>
                {breadcrumbs.map((breadcrumb, index) => (
                  <BreadcrumbItemDirective
                    key={"breadcrumb"+index}
                    iconCss={!!breadcrumb.iconCss && "e-icons " + breadcrumb.iconCss}
                    text={breadcrumb.text}
                    url={breadcrumb.url}
                  />
                ))}
              </BreadcrumbItemsDirective>
            </BreadcrumbComponent>
          </ul>
          {buttonsRight.length > 0 && (
            <hr className="hr_menu"></hr>)}
          <form className="d-flex d-breadcrumb align-items-center">
            <div className={"div_exploit_menu"}>
              {buttonsRight.map((button, index) => {
                switch (button.type) {
                case "button": {
                  return (
                    <ButtonFP
                      key={index}
                      type="submit"
                      onClick={button.onClick}
                      style={"button_navigation"}
                      content={button.content ?? "Retour"}
                    />
                  )
                }
                case "icon": {
                  return (
                    <IconHeaderFP
                      key={index}
                      onClick={button.onClick}
                      icon={button.icon}
                      content={button.content ?? "Retour"}
                      style={button.finalClass}
                    />
                  )
                }
                case "link": {
                  return (
                    <ButtonFP
                      key={index}
                      renderAsLink
                      to={button.to}
                      content={button.content}
                      style={"button_navigation "+button.finalClass}
                      target={button.target}
                    />
                  )
                }
                }
              })}
            </div>
            <hr className="hr_menu"></hr>
            <div className="button_menu_mobile">
              <div className={"input_campagne rowBreadcrumb"}>

              </div>
            </div>
          </form>
        </div>
      </div>
    </nav>
  )
}

export default Breadcrumbs;

import React from "react";
import "./index.css";

const AuthTemplate = ({ children }) => {
  return (
    <div className="login_page col-md-12">
      <div className="login_frame col-md-12">
        <div className={"frame_login_image col-md-6 col-sm-12"}>
          <img
            src={
              "https://plfoxbrfgsapveiqvmdw.supabase.co/storage/v1/object/public/pub-assets/logo/logo.png"
            }
            alt={"Login"}
            className={"login_image col-md-12"}
          />
        </div>
        <div className="login_form col-md-6 col-sm-12">{children}</div>
      </div>
    </div>
  );
};

export default AuthTemplate;

import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import Page from "../../components/Page";
import "./index.css";
import Loading from "../../components/Loading";
import {
  getAllConnectors,
  getAllConnectorsKey,
  getAllDocuments,
  getAllDocumentsKey, getAllSubscriptions,
  getAllSubscriptionsKey,
  getPowensLoginRenew,
  getPowensLoginRenewKey, syncConnectors, syncDocuments,
} from "../../api/powens";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useAuth} from "../../contexts/Auth";
import {getUserByUuid, getUserByUuidKey} from "../../api/users";
import moment from "moment"
import "moment/locale/fr";
import {getDocuments, getDocumentsKey} from "../../api/documents";
import {getSignedUrl} from "../../api/api";
import Dialog from "../../components/Dialog";
import styles from "./index.module.css"
import {getConnectors, getConnectorsKey} from "../../api/connectors";
import Checkbox from "../../components/Checkbox";
import JSZip from "jszip";
import axios from "axios";
import {getEmployees, getEmployeesKey} from "../../api/employees";
import FormInput from "../../components/FormInput";
import {supabase} from "../../lib/api";
moment.locale("fr")
import slugify from 'react-slugify';
import {getCompany, getCompanyKey} from "../../api/companies";


function Dashboard() {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [visuelDial, setVisuelDial] = useState(null)
  const [addCompany, setAddCompany] = useState(false)
  const [companyName, setCompanyName] = useState(false)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const auth = useAuth();
  const queryClient = useQueryClient();
  const {data: user} = useQuery(getUserByUuidKey(auth?.id), () => getUserByUuid(auth?.id), {
    enabled: !!auth?.id
  });

  const {data: employees} = useQuery(getEmployeesKey(user?.id), () => getEmployees(user?.id), {
    enabled: !!user?.id,
  });
  const {data: companies} = useQuery(getCompanyKey(employees[0].company_id), () => getCompany(employees[0].company_id), {
    enabled: !!employees?.[0].company_id
  });

  const {data: documents} = useQuery(getDocumentsKey(user?.id), () => getDocuments(user?.id), {
    enabled: !!user?.id,
    refetchOnWindowFocus: true
  });

  const {data: connectors} = useQuery(getConnectorsKey, getConnectors);

  const { data: login } = useQuery(
    getPowensLoginRenewKey({user_id: user?.user_powens_id}),
    () => getPowensLoginRenew({user_id: user?.user_powens_id}),
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.user_powens_id && !user?.user_powens_token
    }
  );

  const { data: documents_powens, isLoadingDocs } = useQuery(
    getAllDocumentsKey({user_id: user?.user_powens_id, bearer: user?.user_powens_token || login?.access_token}),
    () => getAllDocuments({user_id: user?.user_powens_id, bearer: user?.user_powens_token || login?.access_token}),
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.user_powens_id && (user?.user_powens_token || !!login?.access_token)
    }
  );

  const { data: subscriptions, isLoadingSubs } = useQuery(
    getAllSubscriptionsKey({user_id: user?.user_powens_id, bearer: user?.user_powens_token || login?.access_token}),
    () => getAllSubscriptions({user_id: user?.user_powens_id, bearer: user?.user_powens_token || login?.access_token}),
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.user_powens_id && (!!user?.user_powens_token || !!login?.access_token)
    }
  );

  const { data: connectors_powens } = useQuery(
    getAllConnectorsKey(user?.user_powens_token || login?.access_token),
    () => getAllConnectors(user?.user_powens_token || login?.access_token),
    {
      enabled: !!user?.user_powens_token || !!login?.access_token
    }
  );

  useEffect(() => {
    if(connectors && connectors_powens && connectors?.length !== connectors_powens?.length)
      syncConnectors({connectors, connectors_powens});
  }, [connectors, connectors_powens]);

  useEffect(() => {
    if(documents && documents_powens)
      updateDocument();
  }, [documents, documents_powens]);

  const updateDocument = async () => {
    await syncDocuments({login, user, documents, documents_powens});
    queryClient.invalidateQueries(getDocumentsKey(user?.id));
  }

  const doAddCompany = async () => {
    const company = { name: companyName, slug: slugify(companyName), created_at: new Date(), updated_at: new Date()};
    let { data, error } = await supabase
      .from("companies")
      .insert([company]);
    if(!error) {
      const employee = {user_id: user?.id, company_id: data[0].id, role: 'admin', created_at: new Date(), updated_at: new Date()};
      await supabase
        .from("employees")
        .insert([employee]);
    }

  }

  const addService = () => {
    window.open("https://billinnove-test-sandbox.biapi.pro/2.0/auth/webview/fr/connect?client_id=23459478&redirect_uri=https:%2F%2Fapp.billinnove.com%2F&connector_capabilities=document");
  }

  const visuelDialClose = ()=>{
    setVisuelDial(null)
  }

  const getVisuelData = async (props) => {
    const visuelData = {
      id: props.id,
    }

    setVisuelDial(visuelData);
    const privUrl = await getSignedUrl({bucket: 'priv-documents', path: props.url.replace('priv-documents/', '')});

    setVisuelDial({...visuelData, url: privUrl})
  }

  const toggleCheckbox = label => {
    if (selectedCheckboxes.indexOf(label) > -1) {
      setSelectedCheckboxes((prev) => prev.filter(p => p !== label));
    } else {
      setSelectedCheckboxes((prev) => [...prev, label]);
    }
  }

  const download = async () => {
    downloadMutation.mutate();
  }

  const downloadMutation = useMutation(async () =>
    await doDownload()
  );

  const doDownload = async () => {
    var zip = new JSZip();

    await Promise.all(selectedCheckboxes.map(async function (url) {
      const privUrl = await getSignedUrl({bucket: 'priv-documents', path: url});
      const doc = await axios.get(privUrl, {responseType: 'blob'});
      return zip.file(url, doc.data);
    }))
      .then(function() {
        zip.generateAsync({
          type: "blob"
        })
          .then(function(content) {
            window.open(URL.createObjectURL(content), '_blank')
          });
      })
    return true ;

  }

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedCheckboxes(documents.map((document) => document.url.replace('priv-documents/', '')));
    if (isCheckAll) {
      setSelectedCheckboxes([]);
    }
  };

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={[{ iconCss: "e-home", url: "/", text: "Dashboard" }]}
      />
      <Page>
        <div className={"row"}>
          <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={() => addService()}>Ajouter un service</div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            {selectedCheckboxes.length > 0 && !downloadMutation?.isLoading && (<div onClick={() => download()}>Télécharger</div>)}
            {downloadMutation?.isLoading && <Loading small />}
          </div>
          <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
            {(!subscriptions || isLoadingSubs) ? <Loading small={true} /> :  subscriptions?.length + " services"}
          </div>
          <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            {(!employees || employees.length === 0) ? <div onClick={() => setAddCompany(true)}>Ajouter une entreprise</div> : <div>Entreprise : {companies?.[0]?.name} ({companies?.[0]?.slug}@mail.biliinnove.com)</div>}
          </div>
          <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
            {(!documents || isLoadingDocs) ? <Loading small={true} /> :  documents?.length + " documents"}
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
            {subscriptions && subscriptions.map((subscription, index) => (
              <div key={index}>
                <div>{subscription.number} ({subscription.subscriber})</div>

              </div>
            ))}
          </div>
        </div><div className={"row"}>
          <div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12"}>
            Tous
            <Checkbox
              type="checkbox"
              name="selectAll"
              id="selectAll"
              handleCheckboxChange={handleSelectAll}
              checked={isCheckAll}
            />
            {documents && documents.map((document, index) => (
              <div key={index}>
                <div>{index}</div>
                <div>
                  <Checkbox
                    label={document.url.replace('priv-documents/', '')}
                    handleCheckboxChange={toggleCheckbox}
                    key={document.url.replace('priv-documents/', '')}
                    checked={selectedCheckboxes.includes(document.url.replace('priv-documents/', ''))}
                  />
                </div>
                <div>{document.name}</div>
                <div>{document.issuer}</div>
                <div>{document.total_amount} {document?.symbol}</div>
                <div>{moment(document.date).format("L")}</div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={()=> getVisuelData(document)} style={{cursor: "pointer"}}><img src="../../../../assets/visuel.png" alt="Visuel" width="12.5" /></div>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              </div>
            ))}
            {documents?.length !== documents_powens?.length && <><Loading small={true} />Actualisation des données en cours depuis les fournisseurs...</>}
          </div>
        </div>
        {visuelDial && (<Dialog Class={styles.DocsDePreuve__dial} header={() => { return (<div>Facture</div>) }} width={"900px"} height={"800px"} showDialog={!!visuelDial.url} dialogClose={visuelDialClose} style={{ overflow: "none"}}>
          {!visuelDial?.url ? <div><Loading small={true} /></div> : (<div style={{width: "800px", height: "490px", overflow: "scroll"}}>
            <embed src={visuelDial.url} type={'application/pdf'} className={styles.DocsDePreuve__visuelDial__embed} />
          </div>)}
          <div className={styles.DocsDePreuve__visuelDial}>

            {!visuelDial?.url ? <div><Loading small={true} /></div> : (<a href={visuelDial.url} target={"_blank"} rel={"noreferrer"}>Télecharger le document</a>)}

          </div>
        </Dialog>)}
        {addCompany && (<Dialog Class={styles.DocsDePreuve__dial} header={() => { return (<div>Ajouter une entreprise</div>) }} width={"900px"} height={"800px"} showDialog={!!addCompany} dialogClose={() => setAddCompany(false)} style={{ overflow: "none"}}>
          <div className={styles.DocsDePreuve__visuelDial}>
            <FormInput
              htmlFor="name"
              label="Nom de l'entreprise"
              onChange={(value) => setCompanyName(value.target.value)}
              name="name"
              type="text"
              required
            />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={()=> doAddCompany()} style={{cursor: "pointer"}}><img src="../../../../assets/visuel.png" alt="Visuel" width="12.5" /></div>

          </div>
        </Dialog>)}
      </Page>
    </div>
  );
}

export default Dashboard;

import React, {useState} from "react";
import {supabase} from "../../lib/api";
import { useNavigate} from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Page from "../../components/Page";
import ButtonFP from "../../components/ButtonFP";
import "./index.css"
import CardFP from "../../components/CardFP";
import { useAuth } from "../../contexts/Auth";
import {useQuery} from "react-query";
import {getUserByUuid, getUserByUuidKey} from "../../api/users";
import IconHeaderFP from "../../components/IconHeaderFP/index";


function Profile() {
  const navigate = useNavigate();
  const auth = useAuth();
  const {data: user} = useQuery(getUserByUuidKey(auth?.id), () => getUserByUuid(auth?.id), {
    enabled: !!auth?.id,
  });

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");


  const handleChangePassword = async (value) =>
    setPassword(value.target.value);
  const handleChangePasswordConfirmation = async (value) =>
    setPasswordConfirmation(value.target.value);

  const setChangePassword = async () => {
    if (password === passwordConfirmation) {
      const { user, error } = await supabase.auth.update({
        password: password,
      });

      if (error) {
        alert(error.message);
      } else if (!user && !error) {
        alert("Un email vient de vous être envoyé pour vérification.");
      } else {
        alert("Mot de passe modifié avec succès.");
        setPassword("");
        setPasswordConfirmation("");
      }
    } else {
      alert("Les mots de passe ne correspondent pas. ");
    }
  };

  const handleLogout = async () => {
    // eslint-disable-next-line no-console
    supabase.auth.signOut().catch(console.error);
    navigate("/login");
  };

  const changeTheme = () => {
    const theme = localStorage.getItem("theme");
    if(theme === "dark") {
      localStorage.setItem("theme", "light");
    } else {
      localStorage.setItem("theme", "dark");
    }
    window.location.reload();
  }

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={[
          { iconCss: "e-settings", url: "/profile", text: "Profil" },
        ]}
      />
      <Page>
        <div className={"row"}>
          <div className={"col-xs-12 col-sm-12 col-md-6 col-lg-6"}>
            <CardFP style={"h-400"}>
              <div className={"blocCenter"}>
                <h3 className={"mb-2"}>Mon profil</h3>
                <div className={""}><strong>Nom : </strong></div>
                <div>{user?.nom}</div>
                <div className={""}><strong>Email : </strong></div>
                <div>{auth?.email}</div>
                <div className={"mt-2"}><strong>Téléphone :</strong></div>
                <div>{auth?.phone}</div>
              </div>
            </CardFP>
          </div>
          <div className={"col-xs-12 col-sm-12 col-md-6 col-lg-6"}>
            <CardFP style={"h-400"}>
              <div className={"blocCenter"}>
                <h3 className={"mb-2"}>Changement de mot de passe</h3>
                <div className={"flexDirectionColumn"}>
                  <input className={"mb-2 inputFP"} name="password" type="password" value={password} placeholder={"Mot de passe"} onChange={handleChangePassword} />
                  <input className={"mb-2 inputFP"} name={"passwordConfirmation"} type="password" value={passwordConfirmation} placeholder={"Confirmation du mot de passe"} onChange={handleChangePasswordConfirmation} />
                  <ButtonFP type="submit" onClick={() => setChangePassword()} cssClass={"e-info"} content={"Editer"}></ButtonFP>
                </div>
              </div>
            </CardFP>
          </div>
        </div>

        <div className="rowBetween">
          <ButtonFP type="submit" onClick={() => handleLogout()} cssClass={"e-flat"} content={"Déconnexion"}></ButtonFP>
          <IconHeaderFP onClick={() => changeTheme()} icon={<i className="fas fa-moon" />} />
        </div>

      </Page>
    </div>
  );
}

export default Profile;

import React, {useState} from 'react';

const Checkbox = ({label, handleCheckboxChange, checked}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const toggleCheckboxChange = () => {
    setIsChecked((prev) => (
      {
        isChecked: !prev,
      }
    ));
    handleCheckboxChange(label);
  }

  return (
    <div className="checkbox">
      <label>
        <input
          type="checkbox"
          value={label}
          checked={isChecked}
          onChange={toggleCheckboxChange}
        />

        {label}
      </label>
    </div>
  );
}

export default Checkbox;
import {getData, getTable} from "./api";
import {TABLES} from "../lib/constants";

export const getToastKey = (id) => ["getToast", id];
export const getToast = async (id) => {
  if(id) {
    const toasts = await getData({
      table: TABLES.TOAST,
      filter: {
        eq: {key: "id", value: id}
      },
      showError: false
    })
    return {...toasts[0], position: JSON.parse(toasts[0].position)}
  } else {
    return {};
  }
};

export const getToastsKey = "getToasts";
export const getToasts = async () => getTable(TABLES.TOAST);

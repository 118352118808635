import {getData} from "./api";
import {TABLES} from "../lib/constants";

export const getUsersKey = "getUsers";
export const getUsers = async () => getData({
  table: TABLES.USERS,
  filter: {
    select: "*,gen_campagne(*),gen_environnement(*)",
    order: {key: "id", value: {descending: false}}
  }
});

export const getUsersMapKey = "getUsersMap";
export const getUsersMap = async () => (await getData({
  table: TABLES.USERS,
  filter: {
    order: {key: "id", value: {descending: false}}
  }
})).map((user) => { return {value: user.id, text: user.nom}});

export const getUserByUuidKey = (user_uuid) => ["getUserByUuid", user_uuid];
export const getUserByUuid = async (user_uuid) => {
  if (user_uuid) {
    const users = await getData({
      table: TABLES.USERS,
      filter: {
        eq: {key: "user_uuid", value: user_uuid}
      },
      showError: false
    });
    if(users.length === 0)
      throw "Aucun utilisateur trouvé";

    return users[0];
  } else throw "Aucun utilisateur trouve (uuid non fourni)";
};

import React from "react";

export default function Theme() {
  let theme = localStorage.getItem("theme")||"light"
  // const [stylePath] = useState(
  //   theme === "light" ? "https://cdn.syncfusion.com/ej2/material.css" : "https://cdn.syncfusion.com/ej2/material-dark.css"
  // );
  // const [styleBasePath] = useState(
  //   theme === "light" ? "https://cdn.syncfusion.com/ej2/20.2.36/ej2-base/styles/material.css" : "https://cdn.syncfusion.com/ej2/20.2.36/ej2-base/styles/material-dark.css"
  // );
  // const [styleReactButtonPath] = useState(
  //   theme === "light" ? "https://cdn.syncfusion.com/ej2/20.2.36/ej2-react-buttons/styles/material.css" : "https://cdn.syncfusion.com/ej2/20.2.36/ej2-react-buttons/styles/material-dark.css"
  // );
  // const [styleReactNavigationPath] = useState(
  //   theme === "light" ? "https://cdn.syncfusion.com/ej2/20.2.36/ej2-react-navigations/styles/material.css" : "https://cdn.syncfusion.com/ej2/20.2.36/ej2-react-navigations/styles/material.css"
  // );

  /*useEffect(() => {
    window.addEventListener("storage", () => {
      console.log(JSON.parse(localStorage.getItem("theme")) || "");
    });
  }, []);*/

  if(theme === "light") {
    return (<div>
    </div>);
  } else {
    window.onload = function() {
      document.getElementById('style').href="https://cdn.syncfusion.com/ej2/material-dark.css"
      document.getElementById('style-base').href="https://cdn.syncfusion.com/ej2/20.2.36/ej2-base/styles/material-dark.css"
      document.getElementById('style-react-buttons').href="https://cdn.syncfusion.com/ej2/20.2.36/ej2-react-buttons/styles/material-dark.css"
      document.getElementById('style-react-navigation').href="https://cdn.syncfusion.com/ej2/20.2.36/ej2-react-navigations/styles/material-dark.css"
      document.getElementById('style-react-grid').href="https://cdn.syncfusion.com/ej2/20.2.36/ej2-react-grids/styles/material-dark.css"
      document.documentElement.setAttribute('data-theme', 'dark');
    };

    {/*<link rel="stylesheet" type="text/css" href={styleBasePath} />*/}
    {/*<link rel="stylesheet" type="text/css" href={styleReactButtonPath} />*/}
    {/*<link rel="stylesheet" type="text/css" href={styleReactNavigationPath} />*/}
    return (<div>
      {/*<link rel="stylesheet" type="text/css" href="../../dark.css" />*/}
    </div>);
  }


}

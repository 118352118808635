import React from "react";
import "./index.css";
import {useNavigate} from "react-router-dom";

function CardFP({to="", children, style=""}) {
  const navigate = useNavigate();
  const onClick = () => {
    if (to !== "") {
      navigate(to)
    }
  }
  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={`frameCard ${style}`} onClick={() => onClick()}>
      {children}
    </div>
  )
}

export default CardFP;

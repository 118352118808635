import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { supabase } from "../../lib/api";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(() => supabase.auth.session()?.user ?? null);

  useEffect(() => {
    // Listen for changes on auth state (logged in, signed out, etc.)
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => setAuth(session?.user ?? null)
    )

    return () => {
      listener?.unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}